// TODO just import parts you need { DRACOLoader, ...}
// import * as THREE from 'three'
import { WebGLRenderer, Scene, PerspectiveCamera, Vector3, Color, Fog, RectAreaLight, PointLight, TextureLoader, MeshStandardMaterial, Points, Mesh, EquirectangularReflectionMapping, sRGBEncoding } from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';

var camera, cameraTarget, controls, scene, renderer, animation, material;

// Configure decoder and create loader.
var dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');

// params from URL
const urlParams = new URL(window.location.href).searchParams
// const bgColor = urlParams.get('bg') || 'black'
// const hdri = urlParams.get('hdri') ? decodeURIComponent(urlParams.get('hdri')) : '/textures/equirectangular/kiara_1_dawn_warmer_2.jpg'
const modelSrc = urlParams.get('model') ? decodeURIComponent(urlParams.get('model')) : ''
const ext = url => url.substr(url.lastIndexOf('.'), 4).toLowerCase()

// const whiteRose = '#FFE8DF'
// const whiteYellow = '#FFF7E7'
// const whiteGreen2 = '#FFFFC1'
const whiteGreen = '#FFFFD9' //  // 
// const whiterGreen = '#fcffd1'
const bgColor = whiteGreen
const textureImg = document.getElementById('texturemap')
const hdri = textureImg.getAttribute('src')

init()
animate();

function init() {

  // renderer
  renderer = new WebGLRenderer( { antialias: true } );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  // renderer.shadowMap.enabled = true;
  container.appendChild( renderer.domElement );

  scene = new Scene();
  scene.background = new Color( bgColor );
  scene.fog = new Fog( 0x443333, 1, 4 );

  // camera
  camera = new PerspectiveCamera( 30, window.innerWidth / window.innerHeight, 0.1, 15 );
  // // camera.position.set( 3, 0.25, 3 );
  camera.position.set( 0, 0, 0 );
  cameraTarget = new Vector3(0, 0, 0);
  camera.lookAt( cameraTarget );

  // controls
  controls = new OrbitControls( camera, renderer.domElement );
  // inertia
  controls.enableDamping = true;
  controls.dampingFactor = 0.05
  // rotate
  controls.autoRotate = true;
  controls.enableRotate = true;
  // minimize y rotation
  controls.minPolarAngle = 80 * Math.PI/180
  controls.maxPolarAngle = 100 * Math.PI/180
  
  // zoom
  controls.enableZoom = true
  // WHY DOES MIN DISTANCE CHANGE INITIAL CAMERA DISTANCE ???
  // controls.minDistance = 0.25
  // controls.maxDistance = 0.6

  // controls.update() // must be called after any manual changes to the camera's transform

  // Lights
  // var light = new HemisphereLight( 0x443333, 0x111122 );
  // scene.add( light );

  // var light = new SpotLight();
  // light.angle = Math.PI / 16;
  // light.penumbra = 0.5;
  // light.castShadow = true;
  // light.position.set( - 1, 1, 1 );
  // scene.add( light ); 

    // const width = 10;
    // const height = 10;
    // const intensity = 1;
    // const rectLight = new RectAreaLight( 0xffffff, intensity,  width, height );
    // // rectLight.position.set( 0, 0, 0 );
    // // rectLight.lookAt( 0, 0, 0 );
    // rectLight.position.set.z = 1000
    // scene.add( rectLight )

  // const rectLightHelper = new RectAreaLightHelper( rectLight );
  // rectLight.add( rectLightHelper );

  // LIGHTS FROM RECT LIGHT EXAMPLE
  RectAreaLightUniformsLib.init();

  const rectLight1 = new RectAreaLight( '#FFE511', 5, 10, 4 );
  rectLight1.position.set( 5, 8, 5 );
  scene.add( rectLight1 );

    // const rectLight2 = new RectAreaLight( 0x00ff00, 5, 4, 10 );
    // rectLight2.position.set( 0, 8, 5 );
    // scene.add( rectLight2 );

  // cyan?
  const rectLight3 = new RectAreaLight('#00CCFF', 5, 4, 10 ); // #00CCFF
  rectLight3.position.set( - 5, 8, 15 );
  scene.add( rectLight3 );

  // LIGHTS FROM DISPLACEMENT EXAMPLE
  // const ambientLight = new AmbientLight( 0xffffff, 0.25 );
  // scene.add( ambientLight );

  const pointLight = new PointLight( 'red', 0.5 );
  pointLight.position.z = 2500;
  scene.add( pointLight );

  // const pointLight2 = new PointLight( 0xff6666, 1 );
  // camera.add( pointLight2 );

  const pointLight3 = new PointLight( 'blue', 0.5 );
  pointLight3.position.x = - 1000;
  pointLight3.position.z = -2500;
  scene.add( pointLight3 );

  // ENV MAP
  const textureLoader = new TextureLoader();

  const textureEquirec = textureLoader.load( hdri );
  textureEquirec.mapping = EquirectangularReflectionMapping;
  textureEquirec.encoding = sRGBEncoding;

  // MATERIAL
  material = new MeshStandardMaterial({
    color: 0xffffff,

    metalness: 1,
    roughness: 0,

    envMap: textureEquirec, // important -- especially for metals!
    envMapIntensity: 1, // envMapIntensity
  });

  // debugging OBJ files
  if (ext(modelSrc) === '.obj') {
    console.log('obj', modelSrc)
    const objLoader = new OBJLoader()
    objLoader.load(
      modelSrc,
      // resource loaded...
      function (geometry) {
        // add material?
        geometry.traverse( function ( child ) {
          if ( child instanceof Mesh ) {
            child.material = material;
          }
        });

        // scale roughly down...
        geometry.scale.multiplyScalar(0.0025)
        geometry.position.y = -0.12
        scene.add(geometry)
      },

      // called as loading progresses
      function ( xhr ) {

        // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

      },
      // called when loading has errors
      function ( error ) {

        console.log( 'An error happened' );
        console.error(error)

      }
    )
  } else if (ext(modelSrc) === '.drc') {
    dracoLoader.load(
      // 'models/bunny-test.drc',
      // '/models/output-001-01.drc',
      modelSrc,

      // resource loaded...
      onModelLoaded,

      // called as loading progresses
      function ( xhr ) {

        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

      },
      // called when loading has errors
      function ( error ) {

        console.log( 'An error happened' );
        console.error(error)

      }
    )
  }

  window.addEventListener( 'resize', onWindowResize, false );
  window.addEventListener( 'blur', onWindowBlur);
  window.addEventListener( 'focus', onWindowFocus);
}

function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );

}

function animate() {

  animation = requestAnimationFrame( animate );
  controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
  render();
  // renderer.render( scene, camera );
}

function pause () {
  cancelAnimationFrame( animation )
}

function render() {

  var timer = Date.now() * 0.0003;

  camera.position.x = camera.position.x || Math.sin( timer ) * 0.5;
  camera.position.z = camera.position.z || Math.cos( timer ) * 0.5;
  camera.lookAt( cameraTarget );

  renderer.render( scene, camera );

}

function onWindowBlur () {
  controls.autoRotate = false
}

function onWindowFocus () {
  controls.autoRotate = true
}

function onModelLoaded (bufferGeometry) {
  var geometry;
  
  // Point cloud does not have face indices.
  if (bufferGeometry.index == null) {
    geometry = new Points(bufferGeometry, material);
  } else {
    if (bufferGeometry.attributes.normal === undefined) {
      // var geometryHelper = new GeometryHelper();
      // geometryHelper.computeVertexNormals(bufferGeometry);
      bufferGeometry.computeVertexNormals();
    }
    geometry = new Mesh(bufferGeometry, material);
  }

  // Compute range of the geometry coordinates for proper rendering.
  bufferGeometry.computeBoundingBox();
  var sizeX = bufferGeometry.boundingBox.max.x - bufferGeometry.boundingBox.min.x;
  var sizeY = bufferGeometry.boundingBox.max.y - bufferGeometry.boundingBox.min.y;
  var sizeZ = bufferGeometry.boundingBox.max.z - bufferGeometry.boundingBox.min.z;
  var diagonalSize = Math.sqrt(sizeX * sizeX + sizeY * sizeY + sizeZ * sizeZ);
  
  var scale = 1.0 / diagonalSize * 0.3;
  // var scale = 1.0 / diagonalSize * 0.25;
  
  var midX =
    (bufferGeometry.boundingBox.min.x + bufferGeometry.boundingBox.max.x) / 2;
  var midY =
    (bufferGeometry.boundingBox.min.y + bufferGeometry.boundingBox.max.y) / 2;
  var midZ =
    (bufferGeometry.boundingBox.min.z + bufferGeometry.boundingBox.max.z) / 2;

  geometry.scale.multiplyScalar(scale);
  geometry.position.x = -midX * scale;
  geometry.position.y = -midY * scale;
  geometry.position.z = -midZ * scale;
  geometry.castShadow = true;
  geometry.receiveShadow = true;

  // var selectedObject = scene.getObjectByName("my_mesh");
  // scene.remove(selectedObject);
  // geometry.name = "my_mesh";
  
  // wait for img to load?
  setTimeout(() => scene.add(geometry), 100)
}